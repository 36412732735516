import axios from 'axios'
import {
	  Toast
	} from 'vant';
// const baseUrl = 'https://testdigitalize.cnweisou.net'; // 正式线域名
const baseUrl = 'https://digitization.5g-center.com'; // 测试线域名
let qs = require('qs')
// https://digitization.5g-center.com/Api/Buy/gaoDeBuild  获取 高德商品 价格 商品名称
//https://digitization.5g-center.com/Api/Buy/gaoDeCreate  【参数】 user_name  用户名称  shopname 店铺名称  mobile 手机号码
// https://digitization.5g-center.com/Api/Buy/gaoDePay  【参数】 id 为上个接口返回的id   ，  获取支付参数
// https://digitization.5g-center.com/api/WechatUser/jssdk?url=https://gaode.cnweisou.net 获取微信configz
//https://digitization.5g-center.com/api/WechatUser/getUtoken  参数 code 高德 微信授权 登陆
//https://digitization.5g-center.com/Api/Buy/gaoDeWechatUserPay【参数】 id 为上个接口返回的id   ， utoken 用户登录标识 ，获取支付参数 ~
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
function post(url = '', data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(baseUrl + url, qs.stringify(data))
      .then((res) => {
        resolve(res.data)
		if(res.data.code!=0){
			Toast(res.data.msg)
		}
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function get(url = '') {
  return new Promise((resolve, reject) => {
    axios.get(baseUrl + url)
      .then((res) => {
        resolve(res.data)
		if(res.data.code!=0){
			Toast(res.data.msg)
		}
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  post,
  get
}
