<template>
	<div class="home">
		<!-- 背景图 -->
		<div>
			<img :src="imageHttp+'bg.png'" class="bg_image" />
		</div>
		<!-- 推荐 -->
		<div class="share" @click="dialogShare = true"></div>
		<!-- 推荐引导 -->
		<div class="dialog guide" v-if="dialogShare" @click="dialogShare = false">
			<img :src="imageHttp+'g_guide.png'"  class="guide_image"/>
			<div class="guide_text">点击右上角按钮，分享给朋友</div>
		</div>
		<!-- 入驻按钮 -->
		<div class="join" @click="joinUs"></div>
		<!-- 入驻信息表单 -->
		<div class="dialog" v-if="dialogForm" @click="closeForm">
			<form @click.stop>
				<div class="form">
					<img :src="imageHttp+'g_close.png'" class="from_close" @click="closeForm">
					<div class="form_title">填写信息</div>
					<input type="text" name="user_name" v-model="form.user_name" placeholder="请输入用户名称"
						class="form_input user_name" />
					<input type="text" name="shopname" v-model="form.shopname" placeholder="请输入店铺名称"
						class="form_input shopname" />
					<input type="tel" name="mobile" v-model="form.mobile" placeholder="请输入手机号码"
						class="form_input shopname" />
					<div class="form_button" @click="confirmForm">确认</div>
				</div>
			</form>
		</div>
		<!-- 案例 -->
		<div class="case" >
			<van-swipe @change="onChange">
			  <van-swipe-item v-for="item in 3" :key="item">
				  <img :src="imageHttp+'g_case'+item+'.jpg'" class="case_image"/>
			  </van-swipe-item>
			 <template #indicator>
			    <div class="indicator">
					<div :class="[item==current+1?'indicator_itemActive':'indicator_item']" v-for="item in 3" :key="item"
					:style="{'margin-right':item<3?'0.2rem':''}"></div>
				</div>
			  </template>
			</van-swipe>
		</div>
		<!-- 底部按钮 -->
		<div class="button">
			<div class="button_in">
				<div class="button_left">
					<div class="button_left_top">一年标准版</div>
					<div class="button_left_bottom">￥{{money}}元/年</div>
				</div>
				<div class="button_right" @click="imdBuy">立即购买</div>
				<div class="button_sign">
					<div>限一天内 即将恢复原价</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
	  Toast
	} from 'vant';
	import wx from 'weixin-js-sdk';
	export default {
		name: "Home",
		data() {
			return {
				id: '-1',
				money: '',
				dialogForm: false,
				imageHttp: "https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Gaode/",
				form: {
					user_name: '',
					shopname: '',
					mobile: ''
				},
				confirmbuy:false,
				current: 0,
				dialogShare:false,
				utoken:'',
				isWechat:false,//是否是微信浏览器
			}
		},
		async created() {
			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var userAgent = window.navigator.userAgent.toLowerCase();
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if(userAgent.match(/MicroMessenger/i) == 'micromessenger'){
				this.isWechat = true
				let url = encodeURIComponent('https://gaode.cnweisou.net')
				let appid = 'wxb1b92af6ba8cf662'
				let utoken = window.localStorage.getItem('utoken')
				// 拿到code的页
				if(window.location.search){
					// 获取utoken
					let code = (window.location.search.split('&')[0]).split('=')[1]
					console.log(code,'------code')
					this.$request.post('/api/WechatUser/getUtoken',{code})
					.then(res => {
						if (res.code == 0) {
							this.utoken = res.result.utoken
							window.localStorage.setItem('utoken',res.result.utoken)
							window.location.href = "https://gaode.cnweisou.net"
							window.close()
						}
					})
				}
				// 什么都没有的页
				if(!window.location.search && !utoken){
					// 授权
					window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=wechat#wechat_redirect`
					window.close()
				}
				// 拿到utoken的页
				if(utoken){
					this.utoken = utoken
					console.log(this.utoken,'------utoken')
					window.localStorage.removeItem('utoken')
					// 获取微信config
					this.getWechatConfig()
				}
			}
			this.getGoods()
		},
		methods: {
			// 获取微信config
			async getWechatConfig(){
				let res = await this.$request.get('/api/WechatUser/jssdk?url=https://gaode.cnweisou.net/')
				if (res.code == 0) {
					let data = res.result
					wx.config({
						debug: false,
						appId: data.appId, // 必填，公众号的唯一标识
						timestamp: data.timestamp, // 必填，生成签名的时间戳
						nonceStr: data.nonceStr, // 必填，生成签名的随机串
						signature: data.signature, // 必填，签名
						jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
					});
					wx.checkJsApi({
						jsApiList: ['updateAppMessageShareData','updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
						success: function(res) {
							console.log(res, '------checkJsApi')
							// 以键值对的形式返回，可用的api值true，不可用为false
							// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
						}
					});
					wx.ready(function() { //需在用户可能点击分享按钮前就先调用
						wx.updateAppMessageShareData({
							title: '高德旺铺', // 分享标题
							desc: '点亮高德地图，共享上亿用户，助力商家经营！', // 分享描述
							link: 'https://gaode.cnweisou.net/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Gaode/show.png', // 分享图标
							success: function(res) {
								console.log(res, '------updateAppMessageShareData')
								// 设置成功
							}
						})
						wx.updateTimelineShareData({
							title: '高德旺铺', // 分享标题
							link: 'https://gaode.cnweisou.net/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Gaode/show.png', // 分享图标
							success: function(res) {
								// 设置成功
								console.log(res, '------updateTimelineShareData')
							}
						})
					});
					wx.error(function(res) {
						// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
						console.log(res, '------error')
					});
				}
			},
			// 获取商品
			getGoods() {
				this.$request.post('/Api/Buy/gaoDeBuild')
					.then(res => {
						if (res.code == 0) {
							this.money = res.result.money
						}
					})
			},
			// 立即购买
			imdBuy() {
				this.confirmbuy = true
				if (this.id === '-1') {
					this.dialogForm = true
				} else {
					this.getPay()
				}
			},
			//我要入驻
			joinUs() {
				this.dialogForm = true
			},
			// 关闭表单
			closeForm() {
				this.dialogForm = false
				this.confirmbuy = false
			},
			// 确认提交表单
			confirmForm() {
				this.$request.post('/Api/Buy/gaoDeCreate', this.form)
				.then(res => {
					if (res.code == 0) {
						this.id = res.result
						this.dialogForm = false
						Toast('提交成功')
						if(this.confirmbuy){
							this.getPay()
						}
					}
				})
			},
			// 支付
			getPay(){
				if(this.isWechat){
					this.getWechatPayData()
				}else{
					this.browserPay()
				}
			},
			// 滑动轮播图
			onChange(index) {
			  this.current = index;
			},
			// 浏览器支付
			browserPay(){
				this.$request.post('/Api/Buy/gaoDePay', {
					id: this.id
				})
				.then(res => {
					if (res.code == 0) {
						window.location.href = res.result.mweb_url
					}
				})
			},
			// 获取微信支付参数
			getWechatPayData(){
				this.$request.post('/Api/Buy/gaoDeWechatUserPay', {
					id: this.id,
					utoken:this.utoken
				})
				.then(res => {
					if (res.code == 0) {
						let pay_params = res.result
						if (typeof WeixinJSBridge == "undefined"){
						   if( document.addEventListener ){
						       document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(pay_params), false);
						   }else if (document.attachEvent){
						       document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(pay_params)); 
						       document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(pay_params));
						   }
						}else{
						   this.onBridgeReady(pay_params);
						}
					}
				})
			},
			// 微信支付
			onBridgeReady(pay_params){
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId":pay_params.appId,     //公众号ID，由商户传入     
						"timeStamp":pay_params.timeStamp,//时间戳，自1970年以来的秒数     
						"nonceStr":pay_params.nonceStr, //随机串     
						"package":pay_params.package,     
						"signType":pay_params.signType,  //微信签名方式：     
						"paySign":pay_params.paySign //微信签名 
					}, 
					function(res){
						if(res.err_msg == "get_brand_wcpay_request:ok" ){
						// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						}
					}
				);
			}
		},
	};
</script>

<style type="text/css" lang="scss" scoped>
	.home {
		font-family: Source Han Sans CN;
		color: #333333;
		font-size: 0.32rem;
		background-color: #f6f7f9;
		padding-bottom: 1.5rem;
	}

	.bg_image {
		width: 100%;
		height: auto;
	}
	.dialog {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(2, 2, 2, 0.58);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
	}
	
	.share{
		width: 2.4rem;
		height: 0.7rem;
		top:1rem;
		right: 0.2rem;
		position: absolute;
	}
	
	.guide{
		justify-content: flex-start;
		flex-direction: column;
		.guide_image{
			width: 3.52rem;
			height: 4.66rem;
			margin-top: 0.8rem;
			transform: translateX(0.5rem);
		}
		.guide_text{
			color: #fff;
			font-size: 0.32rem;
			margin-top: 0.5rem;
			font-weight: bold;
		}
	}

	.button {
		padding: 0.3rem 0;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #f6f7f9;

		.button_in {
			width: 6.80rem;
			height: 0.90rem;
			margin: 0 auto;
			display: flex;
			position: relative;
		}

		.button_left {
			width: 3.9rem;
			height: 100%;
			border-radius: 0.45rem 0 0 0.45rem;
			background-color: #1A1C25;
			padding-left: 0.32rem;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			letter-spacing: 0.02rem;

			.button_left_top {
				font-size: 0.30rem;
				color: #ffffff;
				margin-bottom: 0.06rem;
				font-weight: bold;
			}

			.button_left_bottom {
				font-size: 0.26rem;
				color: #d2d2d3;
			}
		}

		.button_right {
			height: 100%;
			flex: 1;
			border-radius: 0 0.45rem 0.45rem 0;
			background: linear-gradient(to right, #fd5130, #fe8e38);
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.34rem;
			font-weight: bold;
			letter-spacing: 0.02rem;
		}

		.button_sign {
			position: absolute;
			width: 2.34rem;
			height: 0.3rem;
			top: -0.1rem;
			left: 1.84rem;
			background: linear-gradient(-90deg, #FC1C22, #FD461F);
			border-radius: 0.09rem 0.09rem 0.09rem 0;
			display: flex;
			align-items: center;
			justify-content: center;

			>div {
				font-size: 0.24rem;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #FFE3D3;
				transform: scale(0.8);
				white-space: nowrap;
			}
		}
	}

	.join {
		position: absolute;
		top: 8.57rem;
		left: 0.48rem;
		right: 0.48rem;
		height: 0.91rem;
	}

	

	.form {
		width: 7.10rem;
		height: 6.77rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.09rem 0.13rem 0rem rgba(188, 188, 188, 0.21);
		border-radius: 0.20rem;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 0.28rem;
		box-sizing: border-box;

		.from_close {
			position: absolute;
			top: 0.28rem;
			right: 0.28rem;
			width: 0.36rem;
			height: 0.36rem;
		}

		.form_title {
			font-size: 0.36rem;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #333333;
			margin-top: 0.44rem;
		}

		.form_input {
			width: 6.54rem;
			height: 0.88rem;
			border: 0.01rem solid #D4D4D4;
			border-radius: 0.44rem;
			font-size: 0.3rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			padding: 0 0.48rem;
			box-sizing: border-box;

			&::-webkit-input-placeholder {
				font-size: 0.30rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #999999;
			}
		}

		.user_name {
			margin-top: 0.46rem;
		}

		.shopname {
			margin-top: 0.34rem;
		}

		.form_button {
			width: 6.54rem;
			height: 0.88rem;
			background: linear-gradient(-90deg, #FE592B, #FE8C29);
			border-radius: 0.44rem;
			line-height: 0.88rem;
			text-align: center;
			font-size: 0.36rem;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #FFFFFF;
			margin-top: 0.50rem;
		}
	}
	
	.case{
		position: absolute;
		top: 11.4rem;
		left: 0.16rem;
		right: 0.14rem;
		.case_image{
			width: 6.25rem;
			height: 2.42rem;
			margin: 0.16rem 0.5rem 0 0.46rem;
		}
		.indicator{
			display: flex;
			margin-top: 0.6rem;
			justify-content: center;
		}
		.indicator_item{
			width: 0.12rem;
			height: 0.12rem;
			background: #D2D2D2;
			border-radius: 50%;
		}
		.indicator_itemActive{
			width: 0.33rem;
			height: 0.12rem;
			background: linear-gradient(-90deg, #FE592B, #FE8C29);
			border-radius: 0.06rem;
		}
	}
</style>
